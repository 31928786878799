<template>
    <div class="flex flex-row">
        <div class="flex flex-column">
            <div v-for="(eixoy, index) of record.eixoY" :key="eixoy.ordem" class="flex flex-row">
                <div class="iterator">{{(index + 1)}}</div>
                <CardEixo :titulo="eixoy.titulo" :id="eixoy.id" :eixo="'y'" class="mr-2 mb-2" @onChangeInputTituloEixo="onChangeInputTituloEixo" />
            </div>
        </div>
        <div class="flex flex-column">
            <div v-for="linha of record.linhas" :key="linha" class="flex flex-row">
                <div v-for="coluna of linha.colunas" :key="coluna">
                    <CardMatriz
                        class="eixo-r mb-2 mr-2"
                        :id="coluna.id"
                        :eixo="coluna.eixo"
                        :titulo="coluna.titulo"
                        :grupos="this.grupos"
                        :grupo="coluna.grupo"
                        @onChangeInputTitulo="onChangeInputTitulo"
                        @onChangeGrupo="onChangeGrupo"
                    />
                </div>
                <div v-for="(eixoX, index) of linha.eixoX" :key="eixoX">
                    <CardEixo
                        :titulo="eixoX.titulo"
                        :id="eixoX.id"
                        :eixo="'x'"
                        class="mr-2 mb-2"
                        @onChangeInputTituloEixo="onChangeInputTituloEixo"
                    />
                    <div class="iterator">{{(index + 1)}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardMatriz from './CardMatriz.vue';
import CardEixo from './CardEixo.vue';

export default {
    emits: ['onChangeInputTitulo', 'onChangeGrupo', 'onChangeInputTituloEixo'],
    props: {
        grupos: Array
    },
    components: {
        CardMatriz,
        CardEixo
    },
    data() {
        return {
            record: []
        };
    },
    methods: {
        onChangeInputTituloEixo(inputValue, idCard, eixo){
            this.$emit('onChangeInputTituloEixo', inputValue, idCard, eixo)
        },
        onChangeInputTitulo(idGrupo, inputValue) {
            this.$emit('onChangeInputTitulo', idGrupo, inputValue);
        },
        loadData(data) {
            this.record = data;
        },
        onChangeGrupo(idGrupo, idCard) {
            this.$emit('onChangeGrupo', idGrupo, idCard);
        }
    }
};
</script>

<style lang="scss">
.eixo-x {
    width: 100%;
    padding: 1rem;
}

.iterator {
    padding-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
