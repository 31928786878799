<template>
    <div :style="style" class="cardMatriz card align-items-center justify-content-center">
        <input type="text" @input="enviaValorInputParaOForm" v-model="valorTitulo" class="inputMatriz" />
        <Dropdown
            class="dropdownGrupos"
            v-model="valorGrupo"
            :options="grupos"
            optionValue="id"
            optionLabel="nome"
            @change="enviaAlteracaoGrupo"
        />
    </div>
</template>

<script>
export default {
    emits: ['onChangeInputTitulo', 'onChangeGrupo'],
    props: {
        id: Number,
        eixo: String,
        titulo: String,
        grupo: Object,
        grupos: Array
    },
    data() {
        return {
            valorTitulo: this.titulo,
            valorGrupo: this.grupo.id
        };
    },
    watch: {
        titulo(valor) {
            this.valorTitulo = valor;
        }
    },
    computed: {
        style() {
            return {
                '--color': this.grupo.cor
            };
        }
    },
    methods: {
        enviaValorInputParaOForm() {
            this.$emit('onChangeInputTitulo', this.grupo.id, this.valorTitulo);
        },

        enviaAlteracaoGrupo(event) {
            const idGrupo = event.value;
            const idCard = this.id;
            this.$emit('onChangeGrupo', idGrupo, idCard);
        }
    }
};
</script>
<style scoped lang="scss">
.cardMatriz {
    background-color:var(--color);
    width: 170px;
    height: 100px;
    border-radius: 10px;
    
}

.inputMatriz {
    border-right-style: none;
    border-top-style: none;
    border-left-style: none;
    border-bottom: none;
    background-color: var(--color);
    width: 100%;
    font-weight: 600;
}

input:focus {
    outline: 0;
}

.dropdownGrupos {
    margin-top: 8px;
    height: 30px;
    background-color: var(--color);
    font-weight: 600;
    color: black;
}
:deep(.p-dropdown-label) {
    color: black;
}
:deep(.p-dropdown) {
    border-top: none;
    border-left: none;
    border-right: none;
}
:deep(.p-dropdown-trigger-icon) {
    color: black;
}
</style>
