<template>
    <div class="cardEixo card">
        <input type="text" @input="enviaValorInputParaOForm" v-model="valorTitulo" class="inputMatriz" />
    </div>
</template>

<script>
export default {
    emits: ['onChangeInputTituloEixoY'],
    props: {
        id: Number,
        titulo: String,
        eixo: String
    },
    data() {
        return {
            valorTitulo: this.titulo
        };
    },
    watch: {
        titulo(valor) {
            this.valorTitulo = valor;
        }
    },
    methods: {
        enviaValorInputParaOForm() {
            this.$emit('onChangeInputTituloEixo', this.valorTitulo, this.id, this.eixo);
        }
    }
};
</script>

<style>
.cardEixo {
    background-color: #fafafa;
    width: 170px;
    height: 100px;
}

.inputMatriz {
    border-right-style: none;
    border-top-style: none;
    border-left-style: none;
    border-bottom: none;
    /* border-bottom: 1px solid grey; */
    background-color: var(--color);
    width: 100%;
    font-weight: 600;
}
</style>
